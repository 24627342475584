.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-bottom;
  position: relative;
  width: var(--custom-emoji-size);
  height: var(--custom-emoji-size);
  flex: 0 0 var(--custom-emoji-size);

  :global(.rlottie-canvas) {
    display: block;
  }
}

.withSparkles {
  position: relative;
}

.sparkles {
  position: absolute;
  inset: -0.25rem;
}

.placeholder {
  width: 85%;
  height: 85%;
  background-color: #77777719;
  border-radius: 0.3125rem;
}

.thumb {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.media {
  width: var(--custom-emoji-size) !important;
  height: var(--custom-emoji-size) !important;

  user-select: none !important;
}

.root, .media, .thumb {
  border-radius: var(--custom-emoji-border-radius) !important;
}

.highlight-catch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: auto !important;
  z-index: 1;
}
